import "styles/pages/page-region-map.scss";

import React, { useState } from "react";
import { Link, graphql } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Breadcrumbs from "components/Breadcrumbs";
import MonumentsMapRegions from "components/MonumentsMapRegions";

const RegionMap = ({ data }) => {
  const [text, setText] = useState(null);
  const [region, setRegion] = useState(null);

  const regionMapData = {
    text,
    setText,
    region,
    setRegion,
  };

  return (
    <Layout headerColor="#FFCE2E">
      <Seo title="Mapa regionów" />
      <SubpageHeader
        image={require("assets/images/region-map-top-bg.jpg").default}
        borderColor="#FFCE2E"
      />
      <Breadcrumbs currentLocationLabel="Mapa regionów" />

      <section className="region-map">
        <div className="container">
          <h2 className="section-title">Tajlandia na wyciągnięcie ręki</h2>
          <div className="row">
            <div className="col-xl-5 offset-xl-1">
              {!text && (
                <>
                  <div className="region-map__description">
                    <p>
                      <strong>Tajlandia to niezwykle różnorodny kraj.</strong>
                      Wszystkie elementy rajskiego wypoczynku w Tajlandii łączą
                      się ze sobą. Tutaj będziesz cieszyć się białymi plażami
                      połączonymi z błękitną wodą morską, niesamowicie pyszną
                      tajską kuchnią, zabawnymi klubami nocnymi, luksusowymi
                      hotelami i oczywiście spa w Tajlandii, które należą do
                      najlepszych na świecie.
                    </p>
                    <p>
                      Kraj obejmuje wiele wysp o niesamowitym pięknie z
                      najlepszymi kurortami i plażami, dlatego bardzo ważne
                      jest, aby zdecydować, dokąd pojechać w samej Tajlandii.
                    </p>
                  </div>
                  <img
                    className="img-fluid"
                    src={
                      require("assets/images/region-map-content-image.jpg")
                        .default
                    }
                    alt=""
                  />
                </>
              )}

              {text === 1 && (
                <>
                  <h4 className="region-map__header">
                    Główne atrakcje części północnej:
                  </h4>
                  <ul className="region-map__list">
                    {data.allWpProvinceNorth.edges.map((item, index) => {
                      if (item.node.acfProvince?.provinceContent !== null) {
                        return (
                          <li key={index}>
                            <Link
                              to={`/prowincje-polnocne${item.node.uri}`}
                              style={{
                                color: "#00d5ce",
                              }}
                            >
                              {item.node.title}
                            </Link>
                          </li>
                        );
                      } else {
                        return <li key={index}>{item.node.title}</li>;
                      }
                    })}
                  </ul>
                </>
              )}
              {text === 2 && (
                <>
                  <h4 className="region-map__header">
                    Główne atrakcje części północno-wschodniej:
                  </h4>
                  <ul className="region-map__list">
                    {data.allWpProvinceNorthEast.edges.map((item, index) => {
                      if (item.node.acfProvince?.provinceContent !== null) {
                        return (
                          <li key={index}>
                            <Link
                              to={`/prowincje-polnocno-wschodnie${item.node.uri}`}
                              style={{
                                color: "#6ad044",
                              }}
                            >
                              {item.node.title}
                            </Link>
                          </li>
                        );
                      } else {
                        return <li key={index}>{item.node.title}</li>;
                      }
                    })}
                  </ul>
                </>
              )}
              {text === 3 && (
                <>
                  <h4 className="region-map__header">
                    Główne atrakcje części centralnej:
                  </h4>
                  <ul className="region-map__list">
                    {data.allWpProvinceCentral.edges.map((item, index) => {
                      if (item.node.acfProvince?.provinceContent !== null) {
                        return (
                          <li key={index}>
                            <Link
                              to={`/prowincje-centralne${item.node.uri}`}
                              style={{
                                color: "#9300b9",
                              }}
                            >
                              {item.node.title}
                            </Link>
                          </li>
                        );
                      } else {
                        return <li key={index}>{item.node.title}</li>;
                      }
                    })}
                  </ul>
                </>
              )}
              {text === 4 && (
                <>
                  <h4 className="region-map__header">
                    Główne atrakcje części wschodniej:
                  </h4>
                  <ul className="region-map__list">
                    {data.allWpProvinceEast.edges.map((item, index) => {
                      if (item.node.acfProvince?.provinceContent !== null) {
                        return (
                          <li key={index}>
                            <Link
                              to={`/prowincje-wschodnie${item.node.uri}`}
                              style={{
                                color: "#ffce2e",
                              }}
                            >
                              {item.node.title}
                            </Link>
                          </li>
                        );
                      } else {
                        return <li key={index}>{item.node.title}</li>;
                      }
                    })}
                  </ul>
                </>
              )}
              {text === 5 && (
                <>
                  <h4 className="region-map__header">
                    Główne atrakcje części południowej:
                  </h4>
                  <ul className="region-map__list">
                    {data.allWpProvinceSouth.edges.map((item, index) => {
                      if (item.node.acfProvince?.provinceContent !== null) {
                        return (
                          <li key={index}>
                            <Link
                              to={`/prowincje-poludniowe${item.node.uri}`}
                              style={{
                                color: "#0260db",
                              }}
                            >
                              {item.node.title}
                            </Link>
                          </li>
                        );
                      } else {
                        return <li key={index}>{item.node.title}</li>;
                      }
                    })}
                  </ul>
                </>
              )}
            </div>
            <div className="col-xl-5 offset-xl-1">
              <MonumentsMapRegions {...regionMapData} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allWpProvinceCentral(sort: { fields: title, order: ASC }) {
      edges {
        node {
          title
          uri
          acfProvince {
            provinceContent {
              provinceContentDescription
            }
          }
        }
      }
    }
    allWpProvinceEast(sort: { fields: title, order: ASC }) {
      edges {
        node {
          title
          uri
          acfProvince {
            provinceContent {
              provinceContentDescription
            }
          }
        }
      }
    }
    allWpProvinceNorth(sort: { fields: title, order: ASC }) {
      edges {
        node {
          title
          uri
          acfProvince {
            provinceContent {
              provinceContentDescription
            }
          }
        }
      }
    }
    allWpProvinceNorthEast(sort: { fields: title, order: ASC }) {
      edges {
        node {
          title
          uri
          acfProvince {
            provinceContent {
              provinceContentDescription
            }
          }
        }
      }
    }
    allWpProvinceSouth(sort: { fields: title, order: ASC }) {
      edges {
        node {
          title
          uri
          acfProvince {
            provinceContent {
              provinceContentDescription
            }
          }
        }
      }
    }
  }
`;

export default RegionMap;
